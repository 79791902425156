var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-12 pb-12"},[_c('v-card',{staticClass:"transparent mx-auto",attrs:{"flat":"","max-width":"1200"}},[_c('v-card-title',{staticClass:"my-5"},[_c('h5',[_c('small',[_vm._v("Services list")])])]),_c('ServiceFilters',{attrs:{"type":_vm.serviceType,"speed":_vm.serviceSpeed,"price":_vm.servicePrice,"discount":_vm.serviceDiscount,"contractTerm":_vm.serviceContractTerm},on:{"update:type":function($event){_vm.serviceType=$event},"update:speed":function($event){_vm.serviceSpeed=$event},"update:price":function($event){_vm.servicePrice=$event},"update:discount":function($event){_vm.serviceDiscount=$event},"update:contractTerm":function($event){_vm.serviceContractTerm=$event},"update:contract-term":function($event){_vm.serviceContractTerm=$event}}}),_c('v-row',{staticClass:"my-5 mx-2 mr-12"},[_c('v-text-field',{staticStyle:{"display":"inline-block","width":"280px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.createNewService}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create new service ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"page":_vm.pagination.page,"fixed-header":"","footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        firstIcon: 'mdi-skip-previous',
        lastIcon: 'mdi-skip-next',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right'
      },"search":_vm.search},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-delete")])],1)]}},{key:"item.legacy",fn:function(ref){
      var item = ref.item;
return [(item.legacy)?_c('v-checkbox',{attrs:{"color":"primary","readonly":"","dense":"","hide-details":""},model:{value:(item.legacy),callback:function ($$v) {_vm.$set(item, "legacy", $$v)},expression:"item.legacy"}}):_vm._e()]}},{key:"item.gstIncEx",fn:function(ref){
      var item = ref.item;
return [(item.gstIncEx)?_c('v-checkbox',{attrs:{"color":"#999","readonly":"","dense":"","hide-details":""},model:{value:(item.gstIncEx),callback:function ($$v) {_vm.$set(item, "gstIncEx", $$v)},expression:"item.gstIncEx"}}):_vm._e()]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [(item.type === 'residential')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-alpha-r-box-outline ")]):_c('v-icon',{attrs:{"color":"#09b"}},[_vm._v(" mdi-alpha-c-box-outline ")])]}}])}),_c('span',{staticClass:"bottom-info mx-4"},[_c('small',[_vm._v(" Total selected services: "+_vm._s(_vm.selectedServicesNumber)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }